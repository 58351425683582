import { useEffect, useState } from 'react';
import './login.css'
import LoginImg from '../../assets/images/login.jpg'
import ErrorIcon from '@mui/icons-material/Error';

//COMPONENTES
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useNavigate } from 'react-router-dom';

export default function Login() {

  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState(false);

  const navigate = useNavigate()

  const setLocalStorage = () => {
    localStorage.setItem('localKey', '123123');
    navigate("/inicio");
  };

  const toCadastro = () => {
    navigate('/cadastro');
    console.log(emailInput)
  }

  function validateEmail(email) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    const validate = regex.test(emailInput);

    if (validate === true) {
      setLocalStorage();
    } else {
      setEmailError(true);
    }
  }

  useEffect(() => {
  }, []);


  return (
    <div className='container'>
      <div className='img-container'>
        <img src={LoginImg} className='img-login' />
      </div>
      <div className='text-login'>
        <h2>SEJA BEM VINDO</h2>
        <p>Para que seu App funcione com 100% de assertividade,
          <a href='/cadastro' className='span-login'> CLIQUE AQUI </a>
          <p>e faça um novo cadastro!</p>
        </p>
      </div>
      <div className='login-form'>
        <input className='email-input' placeholder='Digite seu e-mail' onChange={(e) => setEmailInput(e.target.value)} />
        {emailError
          ?
          <small style={{color: 'orangered', display: 'flex', alignItems: 'center', marginTop: 8}}><ErrorIcon />Email inválido!</small>
          :
          <></>
        }
        <button className='button-login' onClick={() => validateEmail()}>ENTRAR <KeyboardTabIcon sx={{ marginLeft: '15px' }} fontSize='large' /> </button>
        <hr className='hr-login' />
        <button className='register-button' onClick={toCadastro}>NÃO TENHO CONTA <AppRegistrationIcon sx={{ marginLeft: '15px' }} fontSize='large' /> </button>
      </div>
    </div>
  );
}
