import React, { useEffect } from "react";
import './style.css'

import SliderProof from "../../components/sliderproof";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

//IMAGENS
import bannerBonus from "../../assets/images/banner.png"
import tiger from "../../assets/images/tiger.jpg"
import aviator from "../../assets/images/aviator.jpg"
import bacbo from "../../assets/images/bacbo.jpg"
import mines from "../../assets/images/mines.jpg"
import footballstudio from "../../assets/images/footballstudio.jpg"
import mouse from "../../assets/images/mouse.jpg"
import ox from "../../assets/images/ox.jpg"
import rabbit from "../../assets/images/rabbit.jpg"
import roulette from "../../assets/images/roulette.jpg"
import spaceman from "../../assets/images/spaceman.jpg"
import tree from "../../assets/images/tree.jpg"

export default function Home() {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '88%',
    bgcolor: '#000000',
    border: '1px solid #00ba75',
    boxShadow: 24,
    p: 4,
    borderRadius: '6px'
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [openAndroid, setOpenAndroid] = React.useState(false);
  const handleOpenAndroid = () => setOpenAndroid(true);
  const handleCloseAndroid = () => setOpenAndroid(false);


  return (
    <div className="home-container">
      <SliderProof />
      <a target="__blank" href="https://afiliados.mmabet.com/visit/?bta=59853&nci=5343" className="banner-container">
        <img src={bannerBonus} alt="Depósito" width={'100%'} height={'100%'} />
      </a>
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <header className="title">
          <div className="header-title">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M176,112H152a8,8,0,0,1,0-16h24a8,8,0,0,1,0,16ZM104,96H96V88a8,8,0,0,0-16,0v8H72a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0v-8h8a8,8,0,0,0,0-16ZM241.48,200.65a36,36,0,0,1-54.94,4.81c-.12-.12-.24-.24-.35-.37L146.48,160h-37L69.81,205.09l-.35.37A36.08,36.08,0,0,1,44,216,36,36,0,0,1,8.56,173.75a.68.68,0,0,1,0-.14L24.93,89.52A59.88,59.88,0,0,1,83.89,40H172a60.08,60.08,0,0,1,59,49.25c0,.06,0,.12,0,.18l16.37,84.17a.68.68,0,0,1,0,.14A35.74,35.74,0,0,1,241.48,200.65ZM172,144a44,44,0,0,0,0-88H83.89A43.9,43.9,0,0,0,40.68,92.37l0,.13L24.3,176.59A20,20,0,0,0,58,194.3l41.92-47.59a8,8,0,0,1,6-2.71Zm59.7,32.59-8.74-45A60,60,0,0,1,172,160h-4.2L198,194.31a20.09,20.09,0,0,0,17.46,5.39,20,20,0,0,0,16.23-23.11Z"></path></svg>
          </div>
          <span>Jogos Disponiveis</span>
        </header>
        <div className="game-list">
          <a href="/jogos/fortunetiger"><img src={tiger} alt="Mines" width={'100%'} height={'100%'} /></a>
          <a href="/jogos/aviator"><img src={aviator} alt="Mines" width={'100%'} height={'100%'} /></a>
          <a href="/jogos/bacbo"><img src={bacbo} alt="Mines" width={'100%'} height={'100%'} /></a>
          <a href="/jogos/mines"><img src={mines} alt="Mines" width={'100%'} height={'100%'} /></a>
          <a href="/jogos/footballstudio"><img src={footballstudio} alt="Mines" width={'100%'} height={'100%'} /></a>
          <a href="/jogos/mouse"><img src={mouse} alt="Mines" width={'100%'} height={'100%'} /></a>
          <a href="/jogos/ox"><img src={ox} alt="Mines" width={'100%'} height={'100%'} /></a>
          <a href="/jogos/rabbit"><img src={rabbit} alt="Mines" width={'100%'} height={'100%'} /></a>
          <a href="/jogos/roulette"><img src={roulette} alt="Mines" width={'100%'} height={'100%'} /></a>
          <a href="/jogos/spaceman"><img src={spaceman} alt="Mines" width={'100%'} height={'100%'} /></a>
          <a href="/jogos/tree"><img src={tree} alt="Mines" width={'100%'} height={'100%'} /></a>
        </div>
        <header className="title">
          <div className="header-title">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,132.69V40a8,8,0,0,0-16,0v92.69L93.66,106.34a8,8,0,0,0-11.32,11.32Z"></path></svg>
          </div>
          <span>Instalação do App</span>
        </header>
        <div class="install-container">
          <button type="button" onClick={handleOpen}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M128.23,30A40,40,0,0,1,167,0h1a8,8,0,0,1,0,16h-1a24,24,0,0,0-23.24,18,8,8,0,1,1-15.5-4ZM223.3,169.59a8.07,8.07,0,0,0-2.8-3.4C203.53,154.53,200,134.64,200,120c0-17.67,13.47-33.06,21.5-40.67a8,8,0,0,0,0-11.62C208.82,55.74,187.82,48,168,48a72.23,72.23,0,0,0-40,12.13,71.56,71.56,0,0,0-90.71,9.09A74.63,74.63,0,0,0,16,123.4a127,127,0,0,0,40.14,89.73A39.8,39.8,0,0,0,83.59,224h87.68a39.84,39.84,0,0,0,29.12-12.57,125,125,0,0,0,17.82-24.6C225.23,174,224.33,172,223.3,169.59Z"></path></svg> iOS</button>
          <button type="button" onClick={handleOpenAndroid}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M207.06,88.67c-.74-.74-1.49-1.46-2.24-2.17l24.84-24.84a8,8,0,0,0-11.32-11.32l-26,26a111.43,111.43,0,0,0-128.55.19L37.66,50.34A8,8,0,0,0,26.34,61.66L51.4,86.72A113.38,113.38,0,0,0,16,169.13V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V168A111.25,111.25,0,0,0,207.06,88.67ZM92,168a12,12,0,1,1,12-12A12,12,0,0,1,92,168Zm72,0a12,12,0,1,1,12-12A12,12,0,0,1,164,168Z"></path></svg> Android</button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <header className="header-modal">
              <div>
                <h3>Instalação<span style={{ color: '#00ba75', marginLeft: 5 }}>iOS</span></h3><small className="small-modal">Siga os para instalar de maneira correta no seu smartphone</small>
              </div>
              <button type="button" style={{ backgroundColor: 'transparent', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', cursor: 'pointer' }} onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg></button>
            </header>
            <ul style={{ marginTop: '1.5rem', listStyle: 'none', padding: 0 }} className="list-modal">
              <li><div>1</div><span>Abra o aplicativo no Safari</span></li>
              <li><div>2</div><span>Clique no icone de "compartilhamento" no canto inferior do smartphone</span></li>
              <li><div>3</div><span>Clique em "Adicionar á tela de inicio</span></li>
              <li><div>4</div><span>Clique em "Adicionar" no canto superior direito</span></li></ul>
          </Box>
        </Modal>
        <Modal
          open={openAndroid}
          onClose={handleCloseAndroid}
        >
          <Box sx={style}>
            <header className="header-modal">
              <div>
                <h3>Instalação<span style={{ color: '#00ba75', marginLeft: 5 }}>Android</span></h3><small className="small-modal">Siga os para instalar de maneira correta no seu smartphone</small>
              </div>
              <button type="button" style={{ backgroundColor: 'transparent', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', cursor: 'pointer' }} onClick={handleCloseAndroid}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg></button>
            </header>
            <ul style={{ marginTop: '1.5rem', listStyle: 'none', padding: 0 }} className="list-modal">
              <li><div>1</div><span>Clique no icone de "Mais opções" no canto superior direito</span></li>
              <li><div>2</div><span>Depois clique em "Instalar Aplicativo" ou "Adicionar a tela Inicial"</span></li>
              <li><div>3</div><span>Confirme a ação clicando em "Instalar" no popup que aparece na tela</span></li>
            </ul>
          </Box>
        </Modal>
      </section>
    </div>
  );
}

