import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// COMPONENTES
import Navbar from '../components/navbar';
import Footer from '../components/footer';

const useAuth = () => {
  const localKey = localStorage.getItem('localKey');
  return { isAuthenticated: localKey !== null };
};

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();
  console.log(useAuth())

  if (isAuthenticated === false) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

export default PrivateRoute;