import { useState } from 'react';
import './ranking.css'
import { useNavigate } from 'react-router-dom';
import rankingData from '../../data/raking.json'

import logo from '../../assets/images/logo.png'

export default function Ranking() {

  return (
    <div className='ranking-container'>
      <div className='ranking-subcontainer'>
        <header className="ranking-header">
          <img src={logo} alt="METODO VISIONARIO GS" width="80" height="80" />
          <div>
            <span>METODO VISIONARIO GS</span><span style={{ color: '#94a4c4' }}>Top 10 ganhos do dia</span>
          </div>
        </header>
        <div>
          <div className="ranking-map">
            <div style={{ display: 'flex' }}>
              <svg viewBox="0 0 24 24"><path fill="#eeac35" d="M19 5h-2V3H7v2H5c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94.63 1.5 1.98 2.63 3.61 2.96V19H7v2h10v-2h-4v-3.1c1.63-.33 2.98-1.46 3.61-2.96C19.08 12.63 21 10.55 21 8V7c0-1.1-.9-2-2-2zM5 8V7h2v3.82C5.84 10.4 5 9.3 5 8zm14 0c0 1.3-.84 2.4-2 2.82V7h2v1z"></path></svg>
              <p style={{ fontSize: '15px' }}>{rankingData[0].email}</p>
            </div>
            <p style={{ fontSize: '15px' }}>R${rankingData[0].ganho}</p>
          </div>
          <hr className='hr-ranking' />
          <div className="ranking-map">
            <div style={{ display: 'flex' }}>
              <svg viewBox="0 0 24 24"><path fill="#dba268" d="M19 5h-2V3H7v2H5c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94.63 1.5 1.98 2.63 3.61 2.96V19H7v2h10v-2h-4v-3.1c1.63-.33 2.98-1.46 3.61-2.96C19.08 12.63 21 10.55 21 8V7c0-1.1-.9-2-2-2zM5 8V7h2v3.82C5.84 10.4 5 9.3 5 8zm14 0c0 1.3-.84 2.4-2 2.82V7h2v1z"></path></svg>
              <p style={{ fontSize: '15px' }}>{rankingData[1].email}</p>
            </div>
            <p style={{ fontSize: '15px' }}>R${rankingData[1].ganho}</p>
          </div>
          <hr className='hr-ranking' />
          <div className="ranking-map">
            <div style={{ display: 'flex' }}>
              <svg viewBox="0 0 24 24"><path fill="#a8acb2" d="M19 5h-2V3H7v2H5c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94.63 1.5 1.98 2.63 3.61 2.96V19H7v2h10v-2h-4v-3.1c1.63-.33 2.98-1.46 3.61-2.96C19.08 12.63 21 10.55 21 8V7c0-1.1-.9-2-2-2zM5 8V7h2v3.82C5.84 10.4 5 9.3 5 8zm14 0c0 1.3-.84 2.4-2 2.82V7h2v1z"></path></svg>
              <p style={{ fontSize: '15px' }}>{rankingData[2].email}</p>
            </div>
            <p style={{ fontSize: '15px' }}>R${rankingData[2].ganho}</p>
          </div>
          <hr className='hr-ranking' />
          <div className="ranking-map">
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 10 }}>4°</span>
              <p style={{ fontSize: '15px' }}>{rankingData[3].email}</p>
            </div>
            <p style={{ fontSize: '15px' }}>R${rankingData[3].ganho}</p>
          </div>
          <hr className='hr-ranking' />
          <div className="ranking-map">
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 10 }}>5°</span>
              <p style={{ fontSize: '15px' }}>{rankingData[4].email}</p>
            </div>
            <p style={{ fontSize: '15px' }}>R${rankingData[4].ganho}</p>
          </div>
          <hr className='hr-ranking' />
          <div className="ranking-map">
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 10 }}>6°</span>
              <p style={{ fontSize: '15px' }}>{rankingData[5].email}</p>
            </div>
            <p style={{ fontSize: '15px' }}>R${rankingData[5].ganho}</p>
          </div>
          <hr className='hr-ranking' />
          <div className="ranking-map">
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 10 }}>7°</span>
              <p style={{ fontSize: '15px' }}>{rankingData[6].email}</p>
            </div>
            <p style={{ fontSize: '15px' }}>R${rankingData[6].ganho}</p>
          </div>
          <hr className='hr-ranking' />
          <div className="ranking-map">
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 10 }}>8°</span>
              <p style={{ fontSize: '15px' }}>{rankingData[7].email}</p>
            </div>
            <p style={{ fontSize: '15px' }}>R${rankingData[7].ganho}</p>
          </div>
          <hr className='hr-ranking' />
          <div className="ranking-map">
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 10 }}>9°</span>
              <p style={{ fontSize: '15px' }}>{rankingData[8].email}</p>
            </div>
            <p style={{ fontSize: '15px' }}>R${rankingData[8].ganho}</p>
          </div>
          <hr className='hr-ranking' />
          <div className="ranking-map">
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 10 }}>10°</span>
              <p style={{ fontSize: '15px' }}>{rankingData[9].email}</p>
            </div>
            <p style={{ fontSize: '15px' }}>R${rankingData[9].ganho}</p>
          </div>
        </div>
      </div>
    </div>
  );
}