import React from "react";
import './style.css'
import { useNavigate } from "react-router";

import { FaHome, FaCrown, FaCheckCircle  } from "react-icons/fa";


export default function Footer() {

  const navigate = useNavigate()

  function navigateTo(rota) {
    navigate(`/${rota}`);
  }

  return (
    <div className="footer-container">
      <div className="footer-item" onClick={() => navigateTo('inicio')}>
        <FaHome />
        <p>Início</p>
      </div>
      <div className="footer-item" onClick={() => navigateTo('ranking')}>
        <FaCrown />
        <p>Ranking</p>
      </div>
      <div className="footer-item" onClick={() => navigateTo('resultado')}>
        <FaCheckCircle />
        <p>Resultados</p>
      </div>
    </div>
  );
}

