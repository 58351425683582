import React, { useState, useEffect } from "react";
import './spaceman.css'

export default function Spaceman() {

  const [tentativas, setTentativas] = useState('-');
  const [saida, setSaida] = useState('');
  const [maxima, setMaxima] = useState('');
  const [contador, setContador] = useState('--');
  const [ativo, setAtivo] = useState(false);

  useEffect(() => {
    let intervalId;
    if (ativo && contador > 0) {
      intervalId = setInterval(() => {
        setContador(contador - 1);
      }, 1000);
    }
    if (contador === 0) {
      setAtivo(false);
    }
    return () => clearInterval(intervalId);
  }, [contador, ativo]);

  function randomizeNumber(min, max){
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function randomizeNumberFloat(min, max) {
    const num = Math.random() * (max - min) + min;
    return num.toFixed(2);
  }

  function setSignal() {
    setTentativas(randomizeNumber(1, 3));
    setSaida(randomizeNumberFloat(1.00, 1.50)+'x');
    setMaxima(randomizeNumberFloat(1.50, 2.20)+'x');
  }

  const iniciarContador = () => {
    setSignal();
    setAtivo(true);
    setContador(59);
  };

  return (
    <div className="tiger-container">
      <div className="gamestatus-container">
        <div className="gamestatus">
          <span>Válido até</span>
          <span>{contador}</span>
        </div>
      </div>
      <div className="game-signal-spaceman">
        <div class="space-signal"><span style={{marginTop: 6}}>Tentativas</span>{tentativas}</div>
        <div class="space-signal"><span style={{marginTop: 6}}>Saída</span>{saida} - {maxima}</div>
      </div>
      <button class="game-generate-signal" onClick={iniciarContador} disabled={ativo}>{ativo ? 'Aguarde...' : 'Encontrar sinal'}</button>
      <iframe src="https://www.bet777slotss.com/" className="iframe-tiger" />
    </div>
  );
}