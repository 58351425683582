import React, { useState, useEffect } from "react";
import './bacbo.css'

export default function Bacbo() {

  const [tentativas, setTentativas] = useState('-');
  const [color, setColor] = useState('');
  const [contador, setContador] = useState('--');
  const [ativo, setAtivo] = useState(false);
  const [protecao, setProtecao] = useState('-');

  useEffect(() => {
    let intervalId;
    if (ativo && contador > 0) {
      intervalId = setInterval(() => {
        setContador(contador - 1);
      }, 1000);
    }
    if (contador === 0) {
      setAtivo(false);
      setProtecao('-');
    }
    return () => clearInterval(intervalId);
  }, [contador, ativo]);

  function randomizeProtecao(){
    const simOuNao = ['Sim', 'Não'];
    const randomColor = Math.floor(Math.random() * simOuNao.length);
    setProtecao(simOuNao[randomColor])
  }

  function randomizeColor() {
    const cores = ['red', 'DodgerBlue'];
    const randomColor = Math.floor(Math.random() * cores.length);
    return cores[randomColor];
  }

  function setSignal() {
    setTentativas(randomizeProtecao());
    setColor(randomizeColor());
  }

  const iniciarContador = () => {
    setSignal();
    setAtivo(true);
    setContador(59);
  };

  return (
    <div className="tiger-container">
      <div className="gamestatus-container">
        <div className="gamestatus">
          <span>Válido até</span>
          <span>{contador}</span>
        </div>
      </div>
      <div className="game-signal-spaceman">
        <div class="football-signal"><span style={{marginTop: 6}}>Proteção</span>{protecao}</div>
        <div class="football-signal"><span style={{marginTop: 6}}>Entrar no</span>{<div className="entry-color" style={{backgroundColor: `${color}`}}/>}</div>
      </div>
      <button class="game-generate-signal" onClick={iniciarContador} disabled={ativo}>{ativo ? 'Aguarde...' : 'Encontrar sinal'}</button>
      <iframe src="https://www.bet777slotss.com/" className="iframe-tiger" />
    </div>
  );
}