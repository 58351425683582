import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// PAGES
import Home from '../pages/home';
import Login from '../pages/login';
import PrivateRoute from './PrivateRoute';
import Ranking from '../pages/ranking';
import Cadastro from '../pages/cadastro';

// JOGOS
import FortuneTiger from '../pages/games/tiger';
import Mines from '../pages/games/mines';
import Ox from '../pages/games/ox';
import Spaceman from '../pages/games/spaceman';
import Mouse from '../pages/games/mouse';
import Rabbit from '../pages/games/rabbit';
import Football from '../pages/games/footballstudio';
import Aviator from '../pages/games/aviator';
import Roleta from '../pages/games/roleta';
import Tree from '../pages/games/tree';
import Bacbo from '../pages/games/bacbo';
import Resultados from '../pages/resultados';

export default function Rotas() {
  return (

    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/cadastro" element={<Cadastro />} />
        <Route element={<PrivateRoute />}>
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/inicio" element={<Home />} />
          <Route path="/jogos/mines" element={<Mines />} />
          <Route path="/jogos/fortunetiger" element={<FortuneTiger />} />
          <Route path="/jogos/ox" element={<Ox />} />
          <Route path="/jogos/spaceman" element={<Spaceman />} />
          <Route path="/jogos/mouse" element={<Mouse />} />
          <Route path="/jogos/rabbit" element={<Rabbit />} />
          <Route path="/jogos/footballstudio" element={<Football />} />
          <Route path="/jogos/aviator" element={<Aviator />} />
          <Route path="/jogos/mines" element={<Mines />} />
          <Route path="/jogos/roulette" element={<Roleta />} />
          <Route path="/jogos/tree" element={<Tree />} />
          <Route path="/jogos/bacbo" element={<Bacbo />} />
          <Route path="/resultado" element={<Resultados />} />
        </Route>
        <Route path="*" element={<p style={{ color: 'black' }}>Esta página não existe!</p>} />
      </Routes>
    </Router>

  );
}