import React, { useState, useEffect } from "react";
import './mines.css'
import MineEmpty from '../../../assets/images/empty.png'
import Diamond from '../../../assets/images/diamond.png'


export default function Mines() {

  const [ativo, setAtivo] = useState(false);
  const [contador, setContador] = useState('--');
  const [minas, setMinas] = useState('-');
  const [Empty, setEmpty] = useState(MineEmpty);

  const [imagens1, setImagens1] = useState([
    { id: 1, src: Empty },
    { id: 2, src: Empty },
    { id: 3, src: Empty },
    { id: 4, src: Empty },
    { id: 5, src: Empty },
  ]);

  const [imagens2, setImagens2] = useState([
    { id: 1, src: Empty },
    { id: 2, src: Empty },
    { id: 3, src: Empty },
    { id: 4, src: Empty },
    { id: 5, src: Empty },
  ]);

  const [imagens3, setImagens3] = useState([
    { id: 1, src: Empty },
    { id: 2, src: Empty },
    { id: 3, src: Empty },
    { id: 4, src: Empty },
    { id: 5, src: Empty },
  ]);

  const [imagens4, setImagens4] = useState([
    { id: 1, src: Empty },
    { id: 2, src: Empty },
    { id: 3, src: Empty },
    { id: 4, src: Empty },
    { id: 5, src: Empty },
  ]);

  const [imagens5, setImagens5] = useState([
    { id: 1, src: Empty },
    { id: 2, src: Empty },
    { id: 3, src: Empty },
    { id: 4, src: Empty },
    { id: 5, src: Empty },
  ]);

  const trocarImagem = () => {
    const todosImagensSetters = [
      { imagens: imagens1, setImagens: setImagens1 },
      { imagens: imagens2, setImagens: setImagens2 },
      { imagens: imagens3, setImagens: setImagens3 },
      { imagens: imagens4, setImagens: setImagens4 },
      { imagens: imagens5, setImagens: setImagens5 },
    ];
  
    todosImagensSetters.forEach(({ imagens, setImagens }) => {
      if (Math.random() < 0.34) return;
  
      let novasImagens = [...imagens];
      const maxSubstituicoes = 2; 
  
      const substituicoes = Math.max(1, Math.floor(Math.random() * (maxSubstituicoes + 1)));
  
      const indicesJaSubstituidos = [];
  
      for (let i = 0; i < substituicoes; i++) {
        let indiceAleatorio;
        do {
          indiceAleatorio = Math.floor(Math.random() * novasImagens.length);
        } while (indicesJaSubstituidos.includes(indiceAleatorio));
  
        indicesJaSubstituidos.push(indiceAleatorio);
        novasImagens[indiceAleatorio] = { ...novasImagens[indiceAleatorio], src: Diamond }; // Certifique-se de ter a variável/imagem Diamond disponível
      }
      setImagens(novasImagens);
    });
  };

  const resetarImagens = () => {
    const estadoInicial = [
      { id: 1, src: Empty },
      { id: 2, src: Empty },
      { id: 3, src: Empty },
      { id: 4, src: Empty },
      { id: 5, src: Empty },
    ];
    setImagens1(estadoInicial);
    setImagens2(estadoInicial);
    setImagens3(estadoInicial);
    setImagens4(estadoInicial);
    setImagens5(estadoInicial);
  };

  useEffect(() => {
    let intervalId;

    if (ativo) {
      const fim = new Date().getTime() + 59 * 1000;

      intervalId = setInterval(() => {
        const agora = new Date();
        const tempoRestante = Math.round((fim - agora.getTime()) / 1000);

        if (tempoRestante >= 0) {
          setContador(tempoRestante);
        } else {
          clearInterval(intervalId);
          setAtivo(false);
          setContador('--');
          resetarImagens();
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [ativo]);

  const iniciarContador = () => {
    if (!ativo) {
      setAtivo(true);
      setSignal();
      trocarImagem();
      setContador(59);
    }
  };

  function randomizeNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function setSignal() {
    setMinas(randomizeNumber(2, 3));
  }

  return (
    <div className="tiger-container">
      <div className="mines-header">
        <div class="mines-status">
          <div class="quantidade-container">
            <span>N° De Minas</span>
            <span>{minas}</span>
          </div>
          <div style={{ marginTop: '.5rem' }} class="quantidade-container">
            <span>Válidade até</span>
            <span>{contador}</span>
          </div>
        </div>
        <div className="mines-board">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {imagens1.map((imagem) => (
              <div className="mines-board-cell">
                <img key={imagem.id} src={imagem.src} draggable="false" alt="Mines" loading="lazy" width="50" height="50" decoding="async" style={{ color: 'transparent', width: '100%', height: '100%' }} />
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {imagens2.map((imagem) => (
              <div className="mines-board-cell">
                <img key={imagem.id} src={imagem.src} draggable="false" alt="Mines" loading="lazy" width="50" height="50" decoding="async" style={{ color: 'transparent', width: '100%', height: '100%' }} />
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {imagens3.map((imagem) => (
              <div className="mines-board-cell">
                <img key={imagem.id} src={imagem.src} draggable="false" alt="Mines" loading="lazy" width="50" height="50" decoding="async" style={{ color: 'transparent', width: '100%', height: '100%' }} />
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {imagens4.map((imagem) => (
              <div className="mines-board-cell">
                <img key={imagem.id} src={imagem.src} draggable="false" alt="Mines" loading="lazy" width="50" height="50" decoding="async" style={{ color: 'transparent', width: '100%', height: '100%' }} />
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {imagens5.map((imagem) => (
              <div className="mines-board-cell">
                <img key={imagem.id} src={imagem.src} draggable="false" alt="Mines" loading="lazy" width="50" height="50" decoding="async" style={{ color: 'transparent', width: '100%', height: '100%' }} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <button class="game-generate-signal" onClick={iniciarContador} disabled={ativo}>{ativo ? 'Aguarde...' : 'GERAR MINAS'}</button>
      <iframe src="https://www.bet777slotss.com/" className="iframe-tiger" />
    </div>
  );
}