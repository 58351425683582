import Rotas from "./router/router";
import './assets/fonts/fonts.css'

function App() {
  return (
    <>
      <Rotas/>
    </>
  );
}

export default App;
