import { createTheme } from "@mui/material";

const theme = createTheme(
  {
    typography: {
      fontFamily: ['Nunito', 'serif'].join(','),
    },
  }
)

export {theme} ;
