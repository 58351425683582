import React from 'react';
import './resultados.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

//IMAGENS
import Lead from '../../assets/images/prova.png'
import Lead1 from '../../assets/images/prova1.png'
import Lead2 from '../../assets/images/prova2.png'
import Lead3 from '../../assets/images/prova3.png'
import Lead4 from '../../assets/images/prova4.png'

export default function Resultados() {

  return (
    <div className='resultado-container'>
      <div className='resultado-subcontainer'>
        <h2 style={{ textAlign: 'center' }}>O App Hacker com mais resultados do mercado!</h2>
        <div className='swiper-image'>
          <Swiper
            navigation={true}
            modules={[Navigation]}
          >
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={Lead} alt='Depoimento'></img>
            </SwiperSlide>
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={Lead1} alt='Depoimento'></img>
            </SwiperSlide>
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={Lead2} alt='Depoimento'></img>
            </SwiperSlide>
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={Lead3} alt='Depoimento'></img>
            </SwiperSlide>
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={Lead4} alt='Depoimento'></img>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}