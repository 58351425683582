import React from "react";
import './style.css'

//DATA
import dataSlider from '../../data/silder.json'

export default function SliderProof() {
 
  return (
    <div style={{ overflow: 'hidden', marginBottom: '2rem', paddingTop: '1.3rem' }}>
      <div className="slider-container">
        <div style={{ display: 'flex' }}>
          {dataSlider.map((jogador, index) => (
            <div  className="silder-item" key={index}>
              <svg className="svg-class"><path d="m15.45 16.52-3.01-3.01c-.11-.11-.24-.13-.31-.13s-.2.02-.31.13L8.8 16.53c-.34.34-.87.89-2.64.89l3.71 3.7c1.17 1.17 3.07 1.17 4.24 0l3.72-3.71c-.91 0-1.67-.18-2.38-.89zM8.8 7.47l3.02 3.02c.08.08.2.13.31.13s.23-.05.31-.13l2.99-2.99c.71-.74 1.52-.91 2.43-.91l-3.72-3.71c-1.17-1.17-3.07-1.17-4.24 0l-3.71 3.7c1.76 0 2.3.58 2.61.89z"></path><path d="m21.11 9.85-2.25-2.26H17.6c-.54 0-1.08.22-1.45.61l-3 3c-.28.28-.65.42-1.02.42-.36 0-.74-.15-1.02-.42L8.09 8.17c-.38-.38-.9-.6-1.45-.6H5.17l-2.29 2.3c-1.17 1.17-1.17 3.07 0 4.24l2.29 2.3h1.48c.54 0 1.06-.22 1.45-.6l3.02-3.02c.28-.28.65-.42 1.02-.42s.74.14 1.02.42l3.01 3.01c.38.38.9.6 1.45.6h1.26l2.25-2.26c1.17-1.18 1.17-3.1-.02-4.29z"></path></svg>
              <small><b>{jogador.nome}</b> recebeu <b>R$ {jogador.valor}</b> do {jogador.jogo}</small>           
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}