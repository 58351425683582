import React, { useState, useEffect } from "react";
import './tree.css'

export default function Tree() {

  const [normal, setNormal] = useState('--');
  const [turbo, setTurbo] = useState('--');
  const [intercal, setIntercal] = useState('--');
  const [contador, setContador] = useState('--');
  const [ativo, setAtivo] = useState(false);

  useEffect(() => {
    let intervalId;
    if (ativo && contador > 0) {
      intervalId = setInterval(() => {
        setContador(contador - 1);
      }, 1000);
    }
    if (contador === 0) {
      setAtivo(false);
    }
    return () => clearInterval(intervalId);
  }, [contador, ativo]);

  function randomizeNumber(min, max){
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function setSignal() {
    setNormal(randomizeNumber(5, 15));
    setTurbo(randomizeNumber(3, 10));
    setIntercal('Intercalando');
  }

  const iniciarContador = () => {
    setSignal();
    setAtivo(true);
    setContador(59);
  };

  return (
    <div className="tiger-container">
      <div className="gamestatus-container">
        <div className="gamestatus">
          <span>Válido até</span>
          <span>{contador}</span>
        </div>
      </div>
      <div className="game-signal">
        <div class="game-signal-items"><span>👉</span>{normal}</div>
        <div class="game-signal-items"><span>⚡️</span>{turbo}</div>
        <div class="game-signal-items"><span>🚥</span>{intercal}</div>
      </div>
      <button class="game-generate-signal" onClick={iniciarContador} disabled={ativo}>{ativo ? 'Aguarde...' : 'Encontrar sinal'}</button>
      <iframe src="https://www.bet777slotss.com/" className="iframe-tiger" />
    </div>
  );
}