import React, { useState, useEffect } from "react";
import './roleta.css'

export default function Roleta() {

  const [tentativas, setTentativas] = useState('-');
  const [alternativa, setAlternativa] = useState('-');
  const [contador, setContador] = useState('--');
  const [ativo, setAtivo] = useState(false);

  useEffect(() => {
    let intervalId;
    if (ativo && contador > 0) {
      intervalId = setInterval(() => {
        setContador(contador - 1);
      }, 1000);
    }
    if (contador === 0) {
      setAtivo(false);
      setAlternativa('-');
      setTentativas('-');
    }
    return () => clearInterval(intervalId);
  }, [contador, ativo]);

  function randomizeNumber(min, max){
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function randomizeColor() {
    const alternativas = ['ODD', 'EVEN', 'VERMELHO', '01 - 18', '19 - 36'];
    const alternativeRandom = Math.floor(Math.random() * alternativas.length);
    setAlternativa(alternativas[alternativeRandom]);
  }

  function setSignal() {
    setTentativas(randomizeNumber(1, 3));
    randomizeColor();
  }

  const iniciarContador = () => {
    setSignal();
    setAtivo(true);
    setContador(59);
  };

  return (
    <div className="tiger-container">
      <div className="gamestatus-container">
        <div className="gamestatus">
          <span>Válido até</span>
          <span>{contador}</span>
        </div>
      </div>
      <div className="game-signal-spaceman">
        <div class="football-signal"><span style={{marginTop: 6}}>Tentativas</span>{tentativas}</div>
        <div class="football-signal"><span style={{marginTop: 6}}>Entrar no</span>{alternativa}</div>
      </div>
      <button class="game-generate-signal" onClick={iniciarContador} disabled={ativo}>{ativo ? 'Aguarde...' : 'Encontrar sinal'}</button>
      <iframe src="https://www.bet777slotss.com/" className="iframe-tiger" />
    </div>
  );
}