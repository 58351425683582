import React, { useState, useEffect } from "react";
import './aviator.css'

export default function Aviator() {

  const [tentativas, setTentativas] = useState('-');
  const [saida, setSaida] = useState('');
  const [maxima, setMaxima] = useState('');
  const [contador, setContador] = useState('--');
  const [ativo, setAtivo] = useState(false);

  useEffect(() => {
    let intervalId;
  
    if (ativo) {
      const fim = new Date().getTime() + 59 * 1000;
  
      intervalId = setInterval(() => {
        const agora = new Date();
        const tempoRestante = Math.round((fim - agora.getTime()) / 1000);
  
        if (tempoRestante >= 0) {
          setContador(tempoRestante);
        } else {
          clearInterval(intervalId);
          setAtivo(false);
          setContador('--');
          setSaida('');
          setMaxima('');
          setTentativas('-');
        }
      }, 1000);
    }
  
    return () => clearInterval(intervalId);
  }, [ativo]);

  const iniciarContador = () => {
    if (!ativo) {
      setAtivo(true);
      setSignal();
      setAtivo(true);
      setContador(59);
    }
  };

  function randomizeNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function randomizeNumberFloat(min, max) {
    const num = Math.random() * (max - min) + min;
    return num.toFixed(2);
  }

  function setSignal() {
    setTentativas(randomizeNumber(1, 3));
    setSaida(randomizeNumberFloat(1.00, 1.50) + 'x');
    setMaxima(randomizeNumberFloat(1.50, 2.20) + 'x');
  }

  return (
    <div className="tiger-container">
      <div className="gamestatus-container">
        <div className="gamestatus">
          <span>Válido até</span>
          <span>{contador}</span>
        </div>
      </div>
      <div className="game-signal-spaceman">
        <div class="space-signal"><span style={{ marginTop: 6 }}>Tentativas</span>{tentativas}</div>
        <div class="space-signal"><span style={{ marginTop: 6 }}>Saída</span>{saida} - {maxima}</div>
      </div>
      <button class="game-generate-signal" onClick={iniciarContador} disabled={ativo}>{ativo ? 'Aguarde...' : 'Encontrar sinal'}</button>
      <iframe src="https://www.bet777slotss.com/" className="iframe-tiger" />
    </div>
  );
}