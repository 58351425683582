import React from "react";
import './cadastro.css'

import { plataformName } from '../../global'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { useNavigate } from "react-router";

export default function Cadastro() {

  const navigate = useNavigate();

  const setLocalStorage = () => {
    localStorage.setItem('localKey', '123123');
    navigate("/inicio");
  }

  const toLogin = () => {
    setLocalStorage();
  }

  return (
    <div className="cadastro-container">
      <ArrowBackIosIcon sx={{color: '#00ba75', alignSelf: 'start'}} fontSize="large" onClick={toLogin}/>
      <span style={{fontSize: 15}}>Registre-se na {plataformName}</span>
      <iframe src="https://www.bet777slotss.com/" className="cadastro-iframe"/>     
      <button className='backhome-button' onClick={toLogin}>CONCLUIR CADASTRO</button> 
    </div>
  );
}

