import * as React from 'react';
import './style.css'
import { useNavigate } from "react-router";

import { IoExit, IoMenu } from "react-icons/io5";

//COMPONENTS
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

//IMAGE
import Tiger from '../../assets/images/logo.png'

//ICONS MATERIAL
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

export default function Navbar() {

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const navigate = useNavigate()

  const toHome = () => {
    navigate("/inicio");
  }

  const setLocalStorage = () => {
    localStorage.removeItem('localKey');
    navigate("/");
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} className='drawer-container'>
      <img src={Tiger} width={'100%'} alt='Banner' />
      <List >
        <ListItem disablePadding>
          <ListItemButton onClick={toHome}>
            <ListItemIcon>
              <HomeOutlinedIcon sx={{ color: "white" }} fontSize='large' />
            </ListItemIcon>
            <ListItemText>Página Inicial</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <AddOutlinedIcon sx={{ color: "white" }} fontSize='large' />
            </ListItemIcon>
            <ListItemText>Depósito</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <AccountBalanceWalletOutlinedIcon sx={{ color: "white" }} fontSize='large' />
            </ListItemIcon>
            <ListItemText>Saque</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-item">
          <IoMenu onClick={toggleDrawer(true)} size={30} />
        </div>
        <div className="navbar-item">
          <h3 style={{fontWeight: 'bolder'}}>HACKER BLACK GS</h3>
        </div>
        <div className="navbar-item" onClick={() => setLocalStorage()} >
          <IoExit size={30} />
        </div>
      </div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
}